.thin-hr {
  opacity: 0.1;
}

.ul-special {
  display: inline-block;
  padding-left: 15px;
}

.ul-special:hover {
  color: #fff;
  background-color: #174076;
  cursor: pointer;
}

.ul-special2:hover {
  cursor: pointer;
}

.ul-newback {
  background-color: #aaaaaa;
}

a.hydra-link {
  color: #000 !important;
  transition: color 250ms;
}

a.hydra-link:hover {
  color: #4465c8 !important;
}

a.smallbiz-link {
  color: #000 !important;
  transition: color 250ms;
}

a.smallbiz-link:hover {
  color: #ffbe18 !important;
}

a.career-assist-link {
  color: #000 !important;
  transition: color 250ms;
}

a.career-assist-link:hover {
  color: #147aa1 !important;
}

a.wreath-link {
  color: #000 !important;
  transition: color 250ms;
}

a.wreath-link:hover {
  color: #31703e !important;
}
