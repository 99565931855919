.nav-no-shadow {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.nav-shadow {
  -webkit-box-shadow: 0 2px 2px -2px #555;
  -moz-box-shadow: 0 2px 2px -2px #555;
  box-shadow: 0 2px 2px -2px #555;
}
