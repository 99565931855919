// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap");

// Override default variables before the import
$primary: #c597f9; //#8c6c98;
$spacer: 2rem;
// $font-family-base: "Roboto", sans-serif;
// $headings-font-family: "Poppins", sans-serif;
$font-family-base: "Mulish", sans-serif;
$headings-font-family: "Mulish", sans-serif;
$navbar-nav-link-padding-x: 2rem;

$navbar-light-color: #000;
$navbar-light-hover-color: rgba($primary, 1);
$navbar-light-active-color: rgba($primary, 1);

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
//import "bootstrap/dist/css/bootstrap.min.css";

.display-1 {
  margin-bottom: 0 !important;
}

.navbar {
  font-family: "Mulish", sans-serif !important;
}

.navbar-brand {
  font-weight: bold !important;
  color: #000 !important;
}

.nav-link:focus {
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-decoration-color: $primary;
  text-underline-offset: 9px;
}

.numberCircle {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  padding: 8px;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-weight: bold;
  margin: auto;
}

// h1,
// h2 {
//   letter-spacing: 0.3rem;
// }

p {
  line-height: 2rem;
}

.cryptedmail:after {
  content: attr(data-name) "@" attr(data-domain) "." attr(data-tld);
}
